.modal-content {
    background-color: rgb(255, 255, 255);
    border-radius: 7px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        width: $modal-header-height;
        height: $modal-header-height;
        margin: 0;
        padding: 0;
        z-index: 10;
    }
}

.modal-body {
    @include scrollbar;

    .modal-title {
        margin-bottom: 1rem;
    }

    & + & {
        border-top: 1px solid var(--#{$variable-prefix}border-color);
    }
}

.modal-status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $modal-status-size;
    background: $text-muted;
    border-radius: $modal-content-border-radius $modal-content-border-radius 0 0;
}

.modal-header {
    align-items: center;
    min-height: $modal-header-height;
    background: $modal-header-bg;
    padding: 0 $modal-header-height 0 $modal-inner-padding;
    border-top-right-radius: 7px !important;
    border-top-left-radius: 7px !important;
}

.modal-title {
    font-size: $h3-font-size;
    font-weight: $headings-font-weight;
    color: $headings-color;
    line-height: $line-height-base;
}

.modal-footer {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
    border-top: 1px solid #b7b7b71a;
    background-color: #f7f7f794;

    //@if $modal-footer-border-width == 0 {
    //    padding-top: 0;
    //} @else {
    //    padding-top: .75rem;
    //}

    //padding-bottom: .75rem;
}

.modal-blur {
    backdrop-filter: blur(1.6px);
}

.modal-full-width {
    max-width: none;
    margin: 0 $modal-dialog-margin;
}

.modal-backdrop {
    opacity: 0.24;
    z-index: 1050;
    background-color: #182433;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: var(--tblr-backdrop-opacity);
}
