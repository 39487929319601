.breadcrumb {
  padding: 0;
  margin: 0;
  background: transparent;

  a {
    color: $text-muted;

    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumb-item {
  &.active {
    a {
      color: inherit;
      pointer-events: none;
    }
  }
}

@each $name, $symbol in $breadcrumb-variants {
  .breadcrumb-#{$name} {
    --#{$variable-prefix}breadcrumb-divider: "#{quote($symbol)}";
  }
}
