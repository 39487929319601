%validation-lite {
  border-color: var(--#{$variable-prefix}border-color) !important;
}

@each $state, $data in $form-validation-states {
  .form-control.is-#{$state}-lite {
    @extend %validation-lite;
  }

  .form-select.is-#{$state}-lite {
    @extend %validation-lite;
  }
}
