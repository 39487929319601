.btn {
  --#{$variable-prefix}btn-color-text-rgb: var(--#{$variable-prefix}body-color-rgb);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-color: var(--#{$variable-prefix}border-color);
  white-space: nowrap;

  background-color: var(--#{$variable-prefix}btn-color, var(--#{$variable-prefix}card-bg));
  color: var(--#{$variable-prefix}btn-color-text);

  &:hover {
    color: var(--#{$variable-prefix}btn-color-text);
    border-color: rgba(var(--#{$variable-prefix}btn-color-text-rgb), #{$border-active-opacity});
  }

  &:focus:not([disabled]):not(.disabled) {
    border-color: var(--#{$variable-prefix}btn-color, #{$primary});
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }

  .icon {
    width: $icon-size;
    height: $icon-size;
    min-width: $icon-size;
    margin: 0 .5rem 0 -.25rem;
    vertical-align: bottom;
    color: inherit;
  }

  .avatar {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: text-top;
    margin: 0 .5rem 0 -.25rem;
  }

  .icon-right {
    margin: 0 -.25rem 0 .5rem;
  }

  .badge {
    top: auto;
  }
}

%btn-color {
  border-color: $border-color-dark-transparent;

  &:hover {
    color: var(--#{$variable-prefix}btn-color-text);
    background: var(--#{$variable-prefix}btn-color-interactive);
    border-color: var(--#{$variable-prefix}btn-color);
  }
}

%btn-outline {
  background-color: transparent;
  color: var(--#{$variable-prefix}btn-color);
  border-color: var(--#{$variable-prefix}btn-color);

  &:hover {
    background-color: var(--#{$variable-prefix}btn-color);
    color: var(--#{$variable-prefix}btn-color-text);
    border-color: var(--#{$variable-prefix}btn-color);
  }
}

%btn-ghost {
  background: transparent;
  color: var(--#{$variable-prefix}btn-color);
  border-color: transparent;

  &:hover {
    background-color: var(--#{$variable-prefix}btn-color);
    color: var(--#{$variable-prefix}btn-color-text);
    border-color: var(--#{$variable-prefix}btn-color);
  }
}

.btn-link {
  color: $link-color;
  background-color: transparent;
  border-color: transparent;

  .icon {
    color: inherit;
  }

  &:hover {
    color: $link-hover-color;
    border-color: transparent;
  }
}

.btn-lg {
  svg.icon {
    stroke-width: 2;
  }
}

.btn-pill {
  padding-right: 1.5em;
  padding-left: 1.5em;
  border-radius: 10rem;

  &[class*="btn-icon"] {
    padding: 0.375rem 15px;
  }
}

.btn-icon {
  min-width: add($btn-line-height * $btn-font-size + $btn-padding-y * 2, $btn-border-width * 2);

  &.btn-sm {
    min-width: add($btn-line-height * $btn-font-size-sm + $btn-padding-y-sm * 2, $btn-border-width * 2);
  }

  .icon {
    margin: 0 -1em;
  }
}

.btn-square {
  border-radius: 0;
}

.btn-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -.5rem !important;
  margin-right: -.5rem;

  > * {
    margin: 0 .5rem .5rem 0 !important;
  }
}

.btn-floating {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  border-radius: $border-radius-pill;
}

.btn-loading {
  position: relative;
  color: transparent !important;
  text-shadow: none !important;
  pointer-events: none;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    border: $spinner-border-width-sm solid currentColor;
    border-right-color: transparent;
    border-radius: $border-radius-pill;
    color: $white;
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: calc(50% - .5rem);
    top: calc(50% - .5rem);
    animation: spinner-border .75s linear infinite;
  }
}

//
// Button color variations
//
@each $name, $color in theme-colors() {
  .btn-#{$name},
  .btn-outline-#{$name},
  .btn-ghost-#{$name} {
    --#{$variable-prefix}btn-color: #{$color};
    --#{$variable-prefix}btn-color-interactive: #{theme-color-darker($color)};
    --#{$variable-prefix}btn-color-text: #{color-contrast($color)};
  }

  .btn-#{$name} {
    @extend %btn-color;
  }

  .btn-outline-#{$name} {
    @extend %btn-outline;
  }

  .btn-ghost-#{$name} {
    @extend %btn-ghost;
  }
}

//
// Action button
//
.btn-action {
  padding: 0;
  border: 0;
  color: $text-muted;
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;

  &:after {
    content: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover,
  &.show {
    color: $body-color;
    background: $body-bg;
  }

  &.show {
    color: $primary;
  }

  .icon {
    margin: 0;
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
    stroke-width: 1;
  }
}

.btn-actions {
  display: flex;
}