@mixin subheader($include-color: true, $include-line-height: true) {
  font-size: $h6-font-size;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: .04em;

  @if $include-line-height {
    line-height: $h6-line-height;
  }

  @if ($include-color) {
    color: $text-muted;
  }
}

@mixin scrollbar {
  #{if(&, "&", "*")}::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    @include transition(background $transition-time);
  }

  #{if(&, "&", "*")}::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(var(--#{$variable-prefix}body-color-rgb), .16);
  }

  #{if(&, "&", "*")}::-webkit-scrollbar-track {
    background: rgba(var(--#{$variable-prefix}body-color-rgb), .06);
  }

  #{if(&, "&", "*")}:hover::-webkit-scrollbar-thumb {
    background: rgba(var(--#{$variable-prefix}body-color-rgb), .32);
  }

  #{if(&, "&", "*")}::-webkit-scrollbar-corner {
    background: transparent;
  }
}


@mixin autodark-image {
  filter: brightness(0) invert(1);
}

@mixin dark-mode {
  @if $enable-dark-mode {
    $selector: &;

    @media not print {
      @if ($selector) {
        .theme-dark & {
          @content
        }
      } @else {
        .theme-dark {
          @content
        }
      }
    }

    @media not print {
      @media (prefers-color-scheme: dark) {
        @if ($selector) {
          .theme-dark-auto & {
            @content
          }
        } @else {
          .theme-dark-auto {
            @content
          }
        }
      }
    }
  }
}
