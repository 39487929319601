// stylelint-disable declaration-no-important

// All colors
@each $color, $value in theme-colors() {
  .bg-#{"" + $color} {
    color: #ffffff !important;
    background: $value !important;
  }

  .text-#{"" + $color} {
    color: $value !important;
  }

  .bg-#{"" + $color}-lt {
    color: $value !important;
    background: theme-color-lighter($value, true) !important;
  }
}
