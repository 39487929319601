@import 'core';
@import '~@tabler/core/src/scss/demo';
@import "~@iconscout/unicons/css/line.css";


@import "~vue-select/src/scss/vue-select";
@import '~izitoast/dist/css/iziToast.min.css';
@import "~nprogress/nprogress.css";
@import "media-library-pro-styles";

.vs__dropdown-toggle {
    appearance: none;
    display: flex;
    padding: 3px 0 4px 0;
    background: none;
    border: $border-width $border-style $border-color;
    border-radius: $border-radius;
    white-space: normal;
}
.vs__selected-options {
    flex-wrap: nowrap;
    max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

.py-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

.py-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
}
